
import Vue from 'vue'
import Component from 'vue-class-component'
import Moderation from '@/apis/Moderation'
import { Http } from '@/apis/Http'

@Component({
  name: 'DealershipsAdding'
})

export default class DealershipsAdding extends Vue {
  details = {
    dealership: {
      name: null,
      address: null
    },
    managers: [
      {
        id: 0,
        name: null,
        phone: null,
        email: null
      }
    ]
  }

  created() {
    Http.get('/user-enter-dealerships-new')
  }

  addManager() {
    this.details.managers.push(
      {
        id: this.details.managers.length,
        name: null,
        phone: null,
        email: null
      }
    )
  }

  deleteManager(id) {
    this.details.managers.splice(id, 1)
  }

  get isOneManager() {
    return this.details.managers.length === 1
  }

  storeForModeration() {
    Moderation.store(this.details)
      .then(({ data }) => {
        console.log(data)
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Dealerships' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Заполните поля для добавления нового автосалона')
      })
  }
}
